import { TypographyOptions } from "@mui/material/styles/createTypography";

const typography: TypographyOptions = {
  fontFamily: 'Nunito',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  h1: {
    fontSize: "32px",
    lineHeight: "38px",
    fontWeight: "600"
  },
  subtitle1: {
    fontSize: "16px",
    lineHeight: "28px"
  },
  subtitle2: {
    fontSize: "12px",
    lineHeight: "20px"
  },
  h2: {
    fontSize: "28px",
    lineHeight: '33px'
  },
  h3: {
    fontSize: "24px",
    lineHeight: '28px'
  },
  h5: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  body1: {
    fontSize: "18px",
    lineHeight: "28px", 
    fontWeight: 400
  },
  body2: {
    fontSize: "14px",
    lineHeight: "20px", 
    fontWeight: 400
  },
};

export default typography;

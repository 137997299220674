import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

interface BreadcrumbProps {
  pageName: string; // Prop to receive the page name
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ pageName }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {/* Use the pageName prop directly for the first link
      <RouterLink color="rgba(0, 0, 0, 0.54)" to="/dashboard" style={{ textDecoration: 'none', fontSize: '14px', fontWeight: '400' }}>
        Dashboard
      </RouterLink>
      */}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;

        return index === pathnames.length - 1 ? (
          // Use the pageName prop directly for the last link
          <Typography color="text.primary" key={name} style={{ textDecoration: 'none', fontSize: '14px', fontWeight: '400' }}>
            {pageName}
          </Typography>
        ) : (
          // Update the page name and color in the RouterLink below
          <RouterLink color="gray" to={routeTo} key={name} style={{ textDecoration: 'none', fontSize: '14px', fontWeight: '400' }}>
            {name}
          </RouterLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;

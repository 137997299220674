import styled from 'styled-components';

type FlexItemsProps = {
    flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
    justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
    gap?: string
}

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:20px;
    font-size: 14px;
`

export const MainTitle = styled.h1`
    font-size: 32px;
    text-align: center;
    font-family: 'Nunito', sans-serif;
`

export const FlexItems = styled.div`
    display: flex;
    width: 100%;
    flex-direction: ${(props: FlexItemsProps) => props.flexDirection || "row"};
    align-items: ${(props: FlexItemsProps) => props.alignItems || "center"};
    justify-content: ${(props: FlexItemsProps) => props.justifyContent || "flex-end"};
    gap: ${(props: FlexItemsProps) => props.gap || "center"};
`
import styled from "styled-components";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { Form } from "../../common/styled-components/styled";
import { Button, IconButton } from "@mui/material";
import Typography from "../../theme/typography";
import ThemeColors from "../../theme/colors";
import Logo from "../../assets/images/logo.svg";
import { Auth } from "aws-amplify";
import SnackbarComponent from "../snackbar/snackbar";

const ContainerLoginForm = styled.div`
  position: relative;
  background: white;
  padding: 68px 55px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  border: 2px solid ${ThemeColors.primary.gray};
`;
const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const LoginTitle = styled.h1`
  font-weight: ${Typography.h2?.fontWeight};
  font-size: ${Typography.h2?.fontSize};
  line-height: ${Typography.h2?.lineHeight};
  margin: 0;
  margin-bottom: 17px;
`;

const LoginSubTitle = styled.p`
  font-weight: ${Typography.subtitle1?.fontWeight};
  font-size: ${Typography.subtitle1?.fontSize};
  line-height: ${Typography.subtitle1?.lineHeight};
  margin: 0px;
  color: ${ThemeColors.secondary.gray};
  margin-bottom: 17px;
`;

const PrivacyText = styled.p`
  font-weight: ${Typography.fontWeightRegular};
  font-size: ${Typography.subtitle1?.fontSize};
  line-height: ${Typography.subtitle1?.lineHeight};
  color: ${ThemeColors.primary.dark};
  margin: 0;
`;

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [token, setToken] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginTimeout, setLoginTimeout] = useState(false);

  const navigate = useNavigate();
  const handleFormSubmit = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      setIsLoggingIn(true);
      setLoginTimeout(false); // Reset login timeout state

      // Start a timer to show timeout error message
      const loginTimer = setTimeout(() => {
        setLoginTimeout(true);
        setIsLoggingIn(false);
      }, 10000); // Set a timeout of 10 seconds (adjust as needed)

      const user = await Auth.signIn(username, password);

      clearTimeout(loginTimer); // Clear the login timer

      if (user) {
        setToken(user.signInUserSession?.accessToken?.jwtToken);
        navigate("/form", { replace: true });
      }

      setIsLoggingIn(false); // Reset the login processing state
    } catch (error) {
      console.log("error", error);
      setLoginError("Username o password errati");
      setOpenSnack(true);
      setIsLoggingIn(false); // Reset the login processing state
    }
  };

  const isDisabledButton =
    username.length < 1 || password.length < 1 ? true : false;
  return (
    <ContainerLoginForm>
      {loginTimeout && (
        <SnackbarComponent
          severity="error"
          isOpen={true}
          message="An error has occurred. Please try again later."
          handleCloseSnackbar={() => setLoginTimeout(false)}
        />
      )}
      <ContainerTitle>
        <LoginTitle>
          <img src={Logo} alt="logo_cyberrating" />
        </LoginTitle>
        <LoginSubTitle>
          Perfavore, inserisci le tue credenziali per accedere all'area
          riservata
        </LoginSubTitle>
      </ContainerTitle>
      <Form onSubmit={handleFormSubmit}>
        <TextField
          variant="standard"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          fullWidth
          label="Username"
          type="text"
        />
        <TextField
          variant="standard"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
          label="Password"
          type="password"
        />
        <Button
          type="submit"
          variant="contained"
          disabled={isDisabledButton || isLoggingIn} // Disable button while processing login
          style={{
            cursor: `${isDisabledButton || isLoggingIn ? "not-allowed" : ""}`,
            pointerEvents: "auto",
            background: `${isDisabledButton ? "#E0E0E0" : ""}`,
          }}
        >
          {isLoggingIn ? "Logging In..." : "Accedi"}
        </Button>
        {/* Add the "Forgot Password" button 
      <Button
        variant="outlined"
        onClick={() => navigate('/reset-password')} // Adjust the route as needed
        disabled={isLoggingIn} // Disable the button while logging in
        style={{ marginTop: '10px' }}
      >
        Forgot Password?
      </Button>*/}
      </Form>
      {loginError && (
        <SnackbarComponent
          severity="error"
          isOpen={openSnack}
          message={loginError}
          handleCloseSnackbar={() => setOpenSnack(!openSnack)}
        />
      )}
      <PrivacyText>
        Effettuando l'accesso accetti i nostri Termini di servizio e
        l'Informativa sulla privacy
      </PrivacyText>
    </ContainerLoginForm>
  );
};

export default LoginForm;

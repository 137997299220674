import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  SelectChangeEvent,
  Chip,
  MenuItem,
  Button,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import styled, { ThemeProvider } from "styled-components";
import Typography from "../../theme/typography";
import ThemeColors from "../../theme/colors";
import { Form, FlexItems } from "../../common/styled-components/styled";
import React, { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import styles from "./job.module.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AtecoProps } from "../../types/types";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  getTags,
  getAtecoCodes,
  getNationns,
  postJobsPost,
} from "../../api-call";
import customTheme from "../../theme/customtheme";

const LayoutFormJob = styled(Form)`
  background: white;
  padding: 56px;
  border-radius: 15px;
  border: 2px solid ${ThemeColors.primary.gray};
  font-size: ${Typography.h1?.fontSize};
  width: 60%;
`;

const FormJobTitle = styled.h1`
  font-size: ${Typography.h1?.fontSize};
  font-weight: ${Typography.fontWeightBold};
  line-height: ${Typography.h1?.lineHeight};
  margin: 0;
`;

const JobsFormSubTitle = styled.p`
  font-size: ${Typography.body2?.fontSize};
  line-height: ${Typography.body2?.lineHeight};
  color: ${ThemeColors.secondary.gray};
  margin: 0;
`;

const ContainerNations = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;

const ContainerNationsSelect = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
  grid-template-columns: 1fr;
  gap: 16px;
`;

const ContainerDomain = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;

const FlexFields = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;
const Error = styled.p`
  font-size: ${Typography.body2?.fontSize};
  line-height: ${Typography.body2?.lineHeight};
  color: "#B71C1C";
  background: "#FBE4E4";
`;

type jobSuccessMessageType = {
  color: string,
  message: string
}


const JobsForm = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<any | null>([]);
  const [nations, setNations] = useState<string[]>([]);
  const [employees, setEmployees] = useState("");
  const [companyName, setCompanyName] = useState<string>("");
  const [atecoCode, setAtecoCode] = useState<AtecoProps>([]);
  const [selectedAtecoCode, setSelectedAtecoCode] = useState<any | null>(null);
  const [selectedNations, setSelectedNations] = useState<string[]>([""]);
  const [domains, setDomains] = useState<string[]>([]);
  const [domainInputValue, setDomainInputValue] = useState<string>("");
  const [networkInputValue, setNetworkInputValue] = useState<string>("");
  const [networks, setNetworks] = useState<string[]>([]);
  const [error, setError] = useState("");
  const [isValidInput, setIsValidInput] = useState<boolean>(true);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [selectedBranches, setSelectedBranches] = useState<string[]>([""]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [postJobMessage, setJobPostMessage] = useState<jobSuccessMessageType>({
    color: '', message: ''
  });

  const isFormValid = companyName && isValidInput && domains.length > 0;

  const handleSelectBranchChange = (index: number, e: SelectChangeEvent) => {
    const data = [...selectedBranches];
    const { value } = e.target;
    data[index] = value;
    setSelectedBranches(data);
  };

  const handleAddDomain = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault(); // Prevent the default behavior of the comma key
      const trimmedValue = domainInputValue.trim();

      const domainValues = trimmedValue.split(',').map(ip => ip.trim());
      const newDomains = domainValues.filter(checkDomainValidation);

      setIsValidInput(true);

      if (newDomains.length > 0) {
        setDomains(prevDomains => [...prevDomains, ...newDomains]);
        setDomainInputValue(""); // Clear the input after adding the domains
      }
    }
  };

  const handleDeleteDomain = (domainToDelete: string) => {
    setDomains((prevDomains) =>
      prevDomains.filter((domain) => domain !== domainToDelete)
    );
  };

  const isValidCIDR = (cidr: string) => {
    const cidrRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[12]?[0-9])$/;
    return cidrRegex.test(cidr);
  };
  
  const handleAddNetwork1 = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault(); // Prevent the default behavior of the comma key
      const trimmedValue = networkInputValue.trim();
      const values = trimmedValue.split(',').map(value => value.trim());
  
      // Remove duplicates
      const uniqueValues = Array.from(new Set(values));
  
      // Separate valid and invalid networks
      const validNetworks: string[] = [];
      const invalidNetworks: string[] = [];
      uniqueValues.forEach(value => {
        if (isValidCIDR(value)) {
          validNetworks.push(value);
        } else {
          invalidNetworks.push(value);
        }
      });
  
      if (validNetworks.length > 0) {
        setNetworks(prevNetworks => [...prevNetworks, ...validNetworks]);
        setNetworkInputValue(""); // Clear the input after adding the networks
        setError(""); // Clear any previous errors
      }
  
      if (invalidNetworks.length > 0) {
        alert(`Invalid network(s): ${invalidNetworks.join(', ')}`);
      }
    }
  };

  const handleDeleteNetwork = (networkToDelete: string) => {
    setNetworks((prevNetworks) =>
      prevNetworks.filter((network) => network !== networkToDelete)
    );
  };

  const data = {
    company_name: companyName,
    cidr: networks,
    ateco: selectedAtecoCode?.code,
    tags: selectedTags,
    countries: selectedNations,
    domains: domains, // Include the domainData in the data object
    additionalEmails: [],
    type: "Passive",
  };

  //console.log(networkData)

  const typeBranch = ["Sede principale", "Sede secondaria"];

  const isValidNetwork = new RegExp(
    /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(?:\/[0-9]{1,2})?$/
  );
  
  
  const checkNetworkValidation = (network: string) => {
    return isValidNetwork.test(network);
  };
  const isValidDomain = new RegExp(
    /^(?!-)[A-Za-z0-9-]+([.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
  );
  const checkDomainValidation=(domIp:string)=>{
    return isValidDomain.test(domIp)
  }

  /** Submit the form */
  const onFormSubmit = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      setIsButtonDisabled(true);
      setJobPostMessage({
        color: '', message: ''}
      );
      await postJobsPost(data);
      setEmployees("");
      setCompanyName("");
      setNetworkInputValue("");
      setNetworks([]);
      setSelectedTags([]);
      setSelectedAtecoCode(null);
      setDomainInputValue("");
      setDomains([]);
      setSelectedNations([]);
      setTimeout(() => {
        // window.location.href = "/dashboard";
      }, 70000);

      setError(""); // Clear any previous error messages
      setJobPostMessage({color: '#4CAF50', message: `Your job has been taken in charge, check the status from <a href="/Job-listings" style="text-decoration: underline" >job listing page</a> to see the result of the analysis.`})
    } catch (error) {
      // @ts-ignore
      console.log(error.response.data.check.failure_reason);
      // @ts-ignore
      setJobPostMessage({color: '#F44336', message: error.response.data.check.failure_reason});
    } finally {
      setIsButtonDisabled(false)
    }
  };

  /** Update the select nation change by index */

  const handleSelectNationChange = (index: number, e: SelectChangeEvent) => {
    const data = [...selectedNations];
    const { value } = e.target;
    data[index] = value;
    setSelectedNations(data); // set new updated select with nations value
  };


  /** Added new fields */
  const handleFieldClick = () => {
    setSelectedNations([...selectedNations, ""]);
  };

  const handleRemoveField = () => {
    const copyArray = [...selectedNations];
    copyArray.pop();
    setSelectedNations(copyArray);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  async function fetchData() {
    try {
      const atecoCodesResponse = await getAtecoCodes();
      // Extract code and description from the API response
      const atecoCodes = atecoCodesResponse.map(
        (item: { description: any; code: any }) => ({
          code: item.code,
          description: item.description,
        })
      );
      setAtecoCode(atecoCodes);

      // Before setting the `nations` state in the `fetchData` function, sort the array alphabetically.
      const nationssResponse = await getNationns();
      const nations = nationssResponse.map(
        (nation: { name: any }) => nation.name
      );
      const sortedNations = nations.sort((a: any, b: any) =>
        a.localeCompare(b)
      ); // Sort alphabetically
      setNations(sortedNations);

      // Before setting the `tags` state in the `fetchData` function, sort the array alphabetically.
      const tagsResponse = await getTags();
      const tagNames = tagsResponse.map((tag: { name: any }) => tag.name);
      const sortedTags = tagNames.sort((a: any, b: any) => a.localeCompare(b)); // Sort alphabetically
      setTags(sortedTags);

      //console.log("Tag Name:", tagNames);
      //console.log("Ateco Codes:", atecoCodes);
      //console.log("Nations", nations);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (
        error instanceof TypeError &&
        error.message.includes("Failed to fetch")
      ) {
        // The API request failed, indicating that the API is not responding
        setError(
          "Unable to fetch data. Please check your internet connection or try again later."
        );
      } else {
        setError(
          "An error occurred while fetching data. Please try again later."
        );
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <LayoutFormJob>
      <FormJobTitle>
        Analyze the cyber security level of your company
      </FormJobTitle>
      <JobsFormSubTitle>
        Complete the following form with the information about the company you
        want to analyse.
      </JobsFormSubTitle>

      <TextField
        required
        variant="standard"
        label="Company name"
        type="text"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
      />

      {/* ateco code */}
      {!selectedAtecoCode && (
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            sx={{
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
                backgroundColor: "rgba(21, 101, 192, 0.04)",
              },
            }}
            id="selected-code"
            // value={selectedAtecoCode || { code: "", description: "" }}
            onChange={(e, newValue) => setSelectedAtecoCode(newValue)}
            // isOptionEqualToValue={(option, value) => option.code === value.code}
            options={atecoCode}
            getOptionLabel={(option) =>
              `${option.code} ( ${option.description})`
            }
            style={{ color: "black" }} // Default style for the Autocomplete component
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Ateco code"
                sx={{ fontFamily: "Nunito", textTransform: "uppercase" }}
              />
            )}
            renderOption={(
              props,
              option: { code: string; description: string }
            ) => (
              <li {...props}>
                <div style={{ fontFamily: "Nunito" }}>
                  <span style={{ fontSize: "14px", color: "black" }}>
                    {option.code}{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    ({option.description})
                  </span>
                </div>
              </li>
            )}
          />
        </FormControl>
      )}

      {/* Selected Ateco Code Description */}
      {selectedAtecoCode && (
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            sx={{
              fontFamily: "Nunito",
              textTransform: "uppercase",
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
                backgroundColor: "rgba(21, 101, 192, 0.04)",
              },
            }}
            id="selected-code"
            value={selectedAtecoCode || { code: "", description: "" }}
            onChange={(e, value) => setSelectedAtecoCode(value)}
            options={atecoCode}
            getOptionLabel={(option) => `${option.code}` || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                variant="standard"
                label="Ateco Code"
                sx={{ fontFamily: "Nunito", textTransform: "uppercase" }}
              />
            )}
            renderOption={(
              props,
              option: { code: string; description: string }
            ) => (
              <li {...props}>
                <div style={{ fontFamily: "Nunito", margin: "0px" }}>
                  <span>{option.code} </span>
                  <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                    ({option.description})
                  </span>
                </div>
              </li>
            )}
          />

          <TextField
            style={{
              marginTop: "20px",
              fontFamily: "Nunito",
              textTransform: "uppercase",
            }}
            variant="standard"
            label="Description"
            value={selectedAtecoCode.description}
            disabled
            sx={{
              "& .MuiInput-input": {
                fontFamily: "Nunito",
                textTransform: "uppercase",
                fontSize: "12px",
                fontWeight: "400",
              },
            }}
          />
        </FormControl>
      )}

      {/* end ateco code */}

      {/* multiple select tags */}

      <>
        <ThemeProvider theme={customTheme}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              sx={{
                fontFamily: "Nunito",
                "& .MuiAutocomplete-tag": {
                  // Add custom styles for the created tags
                  backgroundColor: "rgba(21, 101, 192, 0.04)", // Background color of the created tag
                  color: "rgba(0, 0, 0, 0.87)", // Text color of the created tag
                  padding: "0px, 8px, 0px, 8px", // Add some margin between chips
                  margin: "5px 0px 5px 0px",
                  "& .MuiChip-deleteIcon": {
                    color: "rgba(21, 101, 192, 0.1)", // Change the color of the delete icon
                    "&:hover": {
                      color: "rgba(21, 101, 192, 0.1)", // Change the color of the delete icon on hover
                    },
                  },
                },
                "& .MuiAutocomplete-option:hover": {
                  // Add your custom hover styles for the options
                  backgroundColor: "rgba(21, 101, 192, 0.1)", // Background color on hover
                  color: "black", // Text color on hover
                },
                "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
                  backgroundColor: "red",
                },
              }}
              id="checkboxes-tags-demo"
              options={tags}
              disableCloseOnSelect
              value={selectedTags}
              onChange={(e, value) => setSelectedTags(value)}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ fontFamily: "Nunito" }}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                    style={{ fontFamily: "Nunito", marginRight: 8 }}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Tags" />
              )}
            />
          </FormControl>
        </ThemeProvider>
      </>

      {/* end multiple select tags */}

      {/* added network select */}
        <Autocomplete
        multiple
        value={networks}
        freeSolo
        options={[]} // Empty array since we are using freeSolo
        inputValue={networkInputValue}
        onInputChange={(event, newInputValue) =>
          setNetworkInputValue(newInputValue)
        }
        //onOpen={() => setIsInputFocused(true)}
        //onClose={() => setIsInputFocused(false)}
        renderInput={(params) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              {...params}
              fullWidth
              variant="standard"
              sx={{ fontFamily: "Nunito" }}
              label={isInputFocused && networks.length > 0 ? "" : "Network"}
              id="dom-textfield"
              onKeyDown={handleAddNetwork1} // Attach the event handler here
            />
          </div>
        )}
        renderTags={(value) =>
value.map((networkInputValue, index) => (
      <Chip
        key={index} // Use the index as the key
        label={networkInputValue}
        onDelete={() => handleDeleteNetwork(networkInputValue)}
        sx={{
          fontFamily: "Nunito",
          // Add custom styles for the created tags
          backgroundColor: "rgba(21, 101, 192, 0.04)", // Background color of the created tag
          color: "rgba(0, 0, 0, 0.87)", // Text color of the created tag
          padding: "0px 8px", // Add some margin between chips
          margin: "5px 0px",
          "& .MuiChip-deleteIcon": {
            color: "rgba(21, 101, 192, 0.1)", // Change the color of the delete icon
            "&:hover": {
              color: "rgba(21, 101, 192, 0.1)", // Change the color of the delete icon on hover
            },
          },
        }}
      />
          ))
        }
      />

      {/*Employees*/}
      <TextField
        variant="standard"
        type="number"
        label="Number of employees"
        value={employees}
        sx={{ fontFamily: "Nunito" }}
        onChange={(e) => setEmployees(e.target.value)}
      />

      {/*Domains*/}
      <Autocomplete
        multiple
        value={domains}
        freeSolo
        options={[]} // Empty array since we are using freeSolo
        inputValue={domainInputValue}
        onInputChange={(event, newInputValue) =>
          setDomainInputValue(newInputValue)
        }
        //onOpen={() => setIsInputFocused(true)}
        //onClose={() => setIsInputFocused(false)}
        renderInput={(params) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/*isInputFocused && domains.length > 0 && (
            <div>
              <InputLabel shrink htmlFor="dom-textfield">
                Domains*
              </InputLabel>
              <span
                style={{
                  //styleName: Body2 dedisse scrips;
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: " 0.10000000149011612px",
                  textAlign: "left",
                }}
              >
                You can insert more than one domain. Click enter to add another
                domain.
              </span>
            </div>
            )*/}
            <TextField
              {...params}
              required
              fullWidth
              variant="standard"
              sx={{ fontFamily: "Nunito" }}
              label={isInputFocused && domains.length > 0 ? "" : "Domains"}
              id="dom-textfield"
              onKeyDown={handleAddDomain} // Attach the event handler here
              error={!isValidInput}
              helperText={
                !isValidInput
                  ? "Invalid domain! Please enter a valid domain."
                  : "You can insert more than one domain. Press Enter or use commas to add."
              }
            />

          </div>
        )}
        renderTags={(value) =>
          value.map((domainInputValue) => (
            <Chip
              label={domainInputValue}
              onDelete={() => handleDeleteDomain(domainInputValue)}
              key={domainInputValue}
              sx={{
                fontFamily: "Nunito",
                // Add custom styles for the created tags
                backgroundColor: "rgba(21, 101, 192, 0.04)", // Background color of the created tag
                color: "rgba(0, 0, 0, 0.87)", // Text color of the created tag
                padding: "0px 8px", // Add some margin between chips
                margin: "5px 0px",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(21, 101, 192, 0.1)", // Change the color of the delete icon
                  "&:hover": {
                    color: "rgba(21, 101, 192, 0.1)", // Change the color of the delete icon on hover
                  },
                },
              }}
            />
          ))
        }
      />

      {/* nations select */}
      <ContainerNations>
        <ContainerNationsSelect>
          {selectedNations.map((nation, index) => (
            <FlexFields key={`select-nations-${index}`} className="flex">
              <FormControl fullWidth variant="standard">
                <InputLabel id={`nation-label-${index}`}>Location</InputLabel>
                <Select
                  labelId={`nation-label-${index}`}
                  id={`nation-select-${index}`}
                  value={nation}
                  label="Location"
                  style={{ fontFamily: "Nunito" }}
                  name="location"
                  onChange={(e: SelectChangeEvent) =>
                    handleSelectNationChange(index, e)
                  }
                >
                  {nations?.map((nation: any, index: number) => (
                    <MenuItem
                      key={`${index}-${nation}`}
                      value={nation}
                      style={{
                        fontFamily: "Nunito",
                        color: selectedNations.includes(nation)
                          ? "rgba(30, 136, 229, 1)"
                          : "inherit",
                        background: selectedNations.includes(nation)
                          ? "rgba(21, 101, 192, 0.04)"
                          : "inherit",
                      }}
                    >
                      {nation}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="standard">
                <InputLabel id={`branch-label-${index}`}>Type</InputLabel>
                <Select
                  labelId={`branch-label-${index}`}
                  id={`branch-select-${index}`}
                  style={{ fontFamily: "Nunito" }}
                  name="branch"
                  value={selectedBranches[index]}
                  label="Type company"
                  onChange={(e: SelectChangeEvent) =>
                    handleSelectBranchChange(index, e)
                  }
                >
                  {typeBranch?.map((branch: any, index: number) => (
                    <MenuItem
                      key={`${index}-${branch}`}
                      value={branch}
                      style={{ fontFamily: "Nunito" }}
                    >
                      {branch}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
            </FlexFields>
          ))}
        </ContainerNationsSelect>
        <FlexItems alignItems="center" justifyContent="flex-start" gap="0">
          <Button
            style={{ fontSize: "14px", textTransform: "capitalize" }}
            color="primary"
            aria-label="add"
            size="small"
            onClick={handleFieldClick}
          >
            <AddIcon style={{ fontSize: "14px" }} />
            Add location
          </Button>
          {selectedNations.length > 1 && (
            <Button
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              color="primary"
              aria-label="remove"
              size="small"
              onClick={handleRemoveField}
            >
              <RemoveIcon style={{ fontSize: "14px" }} />
            </Button>
          )}
        </FlexItems>
      </ContainerNations>

      {/*end nations */}
      <Button
        style={{ textTransform: "capitalize" }}
        onClick={onFormSubmit}
        variant="contained"
        disabled={!isFormValid || isButtonDisabled}
        className={styles.submitbutton}
      >
        Submit
      </Button>
        {postJobMessage.message.length > 0 && (
        <div style={{border: `1px solid ${postJobMessage.color}`, display: 'flex', alignItems: 'center', padding: '10px'}}>
          <ErrorOutlineIcon style={{color: postJobMessage.color}} />
          <div
            style={{
              padding: "10px",
              fontFamily: "Nunito",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
              color: postJobMessage.color
            }}
          >
            <p dangerouslySetInnerHTML={{ __html: postJobMessage.message }}></p>
          </div>
        </div>
      )}

      {error && (
        <Error className={styles.error}>
          <ErrorIcon color="error" />
          {error}
        </Error>
      )}


    </LayoutFormJob>
  );
};

export default JobsForm;

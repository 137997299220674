import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/login'
import Dashboard from './pages/dashboard';
import Form from "./pages/form";
import PageNotFound from "./pages/404";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import JobsTabel from "./pages/job-details";
import RadarChartPage from "./pages/charts"
import ResetPassword from './components/login-form/reset-password'; // Import the reset password component

Amplify.configure(awsExports);

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          {/*<Route path="/reset-password" element={<ResetPassword />} /> */}
          <Route path="Form" element={<Form />} />
          <Route path="Job-listings" element={<Dashboard />} />
          <Route path="Job-listings/:jobId" element={<JobsTabel/>} />
          <Route path="Job-listings/:jobId/:companyId" element={<RadarChartPage />} />
        </Route>
        <Route path="jobs">
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

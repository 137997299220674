import './style.css';
import styled from "styled-components";
import typography from "../../theme/typography";
import ThemeColors from "../../theme/colors";

export const ContainerLoader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ContainerBall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoaderText = styled.p`
  font-weight: ${typography.fontWeightBold};
  font-size: ${typography.h3?.fontSize};
  line-height: ${typography.h3?.lineHeight};
  color: ${ThemeColors.primary.dark};
`

const Loader = () => {
    return (
        <ContainerLoader className="dots-container">
            <ContainerBall>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </ContainerBall>
            <LoaderText>Sto caricando i dati...</LoaderText>
        </ContainerLoader>

    )
}

export default Loader;
import Layout from "../components/layout/layout";
import styled from "styled-components";
import typography from "../theme/typography";
import ThemeColors from "../theme/colors";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import JobDetailsPage from "../components/jobs-form/job-deatails-form";
import Breadcrumb from "../components/breadcrumbs/breadcrumb";

const ContainerHeader = styled.div`
    margin-top: 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    ;
  `;
const ContainerPageTitle = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  `;

  const PageTitle = styled.div`
    font-weight: ${typography.fontWeightBold};
    font-size: ${typography.h3?.fontSize};
    line-height: ${typography.h3?.lineHeight};
    color: ${ThemeColors.primary.dark};
  `;
  const ContainerBreadcrumb = styled.div`
  margin-bottom: 40px;
`;

const JobsTabel = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const navigate = useNavigate();

  //redirect user if is not logged in
  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: false })
      .then((data) => {
        //console.log(data)
        setUserLoggedIn(true);
      })
      .catch((error) => {
        setUserLoggedIn(false);
        // Redirect nel caso di utente non loggato
        navigate("/login", { replace: true });
      });
  }, [navigate]);

  return (
    <>
      {userLoggedIn && (
        <Layout maxWidth='lg'>
          <ContainerHeader>
          <ContainerPageTitle>
            <PageTitle>Details</PageTitle>
          </ContainerPageTitle>
          <ContainerBreadcrumb>
            <Breadcrumb pageName={"Details"} />
          </ContainerBreadcrumb>
          </ContainerHeader>
          <JobDetailsPage />
        </Layout>
      )}
    </>
  );
};

export default JobsTabel;

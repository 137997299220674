import LoginForm from "../components/login-form/login-form";
import styled from 'styled-components'

const PageContainer = styled.div`
    width: 620px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const Login = () => {
    return (
        <PageContainer>
            <LoginForm />
        </PageContainer>   
    )
}

export default Login;
import Container from '@mui/material/Container';
import Navbar from '../navbar/navbar';
import Menu from '../navbar/navmenu';

import { Breakpoint } from '@mui/material';

const Layout = ({children, maxWidth = 'xl'} : {children: React.ReactNode, maxWidth?: false | Breakpoint | undefined}) => {
    return (
        <>
            <Navbar>
                <Menu/>
            </Navbar>
            <Container maxWidth={maxWidth}>
                <main style={{position: 'relative'}}> {children} </main>
            </Container>
        </>
    )
}

export default Layout;
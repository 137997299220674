import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./navmenu.module.css";

const Menu = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const navigate = useNavigate();
  

  const handleLogoutButton = async () => {
    try {
      // Clear local storage
      localStorage.clear();
  
      // Sign out the user
      await Auth.signOut({ global: true });
  
      // Force a full page reload
      window.location.reload();
    } catch (error) {
      console.log(error, 'error');
    }
  };

  //redirect user if is not logged in
  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: false })
      .then((data) => {
        //console.log(data)
        setUserLoggedIn(true);
      })
      .catch((error) => {
        setUserLoggedIn(false);
        // Redirect nel caso di utente non loggato
        navigate("/login", { replace: true });
      });
  }, [navigate]);

  return (
    <>
      {userLoggedIn && (
        <div className={styles.menu}>
          <nav
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px",
              fontWeight: 600,
              fontSize: "17px",
              alignContent: "space-between",
              color: "rgba(0, 0, 0, 0.54)",
              marginTop: "12px",
            }}
          >
            <Link to="/Job-listings">Job Listings</Link>
            <Link to="/Form">Job Input</Link>
            {/*<Link to="/Analytics">Analytics</Link>*/}
            <Link onClick={handleLogoutButton} to={"/login"}>Logout</Link>
          </nav>
        </div>
      )}
    </>
  );
};

export default Menu;

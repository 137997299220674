type Colors = {
    primary : {
        dark: string,
        blue: string,
        red: string
        gray: string
    },
    secondary: {
        blue: string,
        gray: string,
        green: string,
        orange: string
    }
}

const ThemeColors : Colors = {
    primary: {
        dark: '#000000',
        blue: '#1565C0',
        red: '#F44336',
        gray: '#ECECED'
    },
    secondary: {
        blue: '#1E88E5',
        gray: 'rgba(0, 0, 0, 0.54)',
        green: '#4CAF50',
        orange: '#FF9800'
    },
}

export default ThemeColors;
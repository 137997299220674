import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import { Menu, MenuItem } from '@mui/material' ;
import { useState } from 'react';
import Typography from "../../theme/typography";
import Logo from '../../assets/images/logo.svg';
import { Auth } from 'aws-amplify';

const Header = styled.header`
    width: 100%;
    background: white;
    z-index: 50;
    position: sticky;
    top: 0;
    //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`

const ContainerItems = styled.div`
    display: flex;
    justify-items: flex-start;
    align-items: center;
`

const ContainerLogo = styled.div`
    cursor: pointer;
`

const LogoLink = styled(Link)`
    font-size: ${Typography.h3?.fontSize};
    line-height: ${Typography.h3?.lineHeight};
    font-weight: ${Typography.fontWeightBold};
    color: black;
    text-decoration: none;
    cursor: 'pointer';
    text-decoration: none;
`
const ContainerChildren = styled.div`
    margin-left: auto;
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
`
const ContainerLogoutButton = styled.div`
   //margin-left: auto;
`

const Navbar = ({children} : {children: React.ReactNode}) => {
    const [menuPosition, setMenuPosition] = useState<null | HTMLElement>(null);
    const open = Boolean(menuPosition);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        //set anchor of popover near the button that i clicked
        setMenuPosition(event.currentTarget);
      };

    const handleCloseMenu = () => {
        setMenuPosition(null)
    }
    
    const navigate = useNavigate();
    const handleLogoutButton = async () => {
        try{
            await Auth.signOut({global: true})
            .then(data => navigate('/login', {replace: true}))
        } catch (error) {
            console.log(error, 'error')
        }
    }

    return (
        <Header>
            <Container maxWidth='lg'>
                <ContainerItems>
                    <ContainerLogo>
                        <LogoLink to={'/Job-listings'}> <img src={Logo} alt="logo_cyberrating" /> </LogoLink>
                    </ContainerLogo>
                    <ContainerChildren>
                    {children}
                    </ContainerChildren>
                    {/*<ContainerLogoutButton>
                        <Button
                            id='logout-button'
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg>
                        </Button>
                        <Menu
                            open={open}
                            onClose={handleCloseMenu}
                            anchorEl={menuPosition} //anchor of popover
                        >
                            <MenuItem onClick={handleLogoutButton}>Logout</MenuItem>
                        </Menu>
                        </ContainerLogoutButton>*/}
                </ContainerItems>
            </Container>
        </Header>
    )
}

export default Navbar;
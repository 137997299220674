import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  typography: {
    fontFamily: "Nunito", // Replace "Nunito" with your desired font family
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          "&:hover": {
            backgroundColor: "rgba(21, 101, 192, 0.1)", // Replace with your hover background color
          },
        },
        listbox: {
          "& .MuiAutocomplete-option.Mui": {
            backgroundColor: "red", // Background color for focused option
          },
        },
      },
    },
  },
});

export default customTheme;

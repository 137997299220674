import { useEffect, useState, useMemo } from "react";
import { getJobs } from "../../api-call";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Stack,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "./jobtable.module.css";
import Arrowicon from "../../assets/images/arrow.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Loader from "../loader/loader";

interface JobItem {
  id: any;
  domains: string[];
  cidr: string[];
  company: string;
  tags: string[];
  atecoCode: string;
  owner: string;
  email: string;
  createdAt: string;
  status: string;
  type: string;
  [key: string]: any; // Index signature allowing indexing with a string
}

const JobsTable = () => {
  const [jobs, setJobs] = useState<JobItem[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleRowClick = (jobId: any) => {
    navigate(`/Job-listings/${jobId}`);
  };

  const sortData = (column: string) => {
    if (sortedColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedJobs = useMemo(() => {
    return jobs.slice().sort((a, b) => {
      if (sortedColumn === null) return 0;

      const sortValueA: string | number = a[sortedColumn];
      const sortValueB: string | number = b[sortedColumn];

      if (sortedColumn === "id" || sortedColumn === "riskScore" || sortedColumn === 'duration') {
        // Numeric sorting for job IDs
        const numericSortValueA = parseInt(sortValueA as string);
        const numericSortValueB = parseInt(sortValueB as string);
        return sortDirection === "asc" ? numericSortValueA - numericSortValueB : numericSortValueB - numericSortValueA;
      } else if (sortedColumn === "companyName") {
        // Alphabetic sorting for company names
        const stringA = (sortValueA || "").toString().toLowerCase();
        const stringB = (sortValueB || "").toString().toLowerCase();
        return sortDirection === "asc" ? stringA.localeCompare(stringB) : stringB.localeCompare(stringA);
      } else if (sortedColumn === "createdAt") {
        const dateValueA = (sortValueA || "").toString();
        const dateValueB = (sortValueB || "").toString();
        return sortDirection === "asc" ? dateValueA.localeCompare(dateValueB) : dateValueB.localeCompare(dateValueA);
      } else {
        // Alphanumeric sorting for non-numeric columns
        const stringA = (sortValueA || "").toString().toLowerCase();
        const stringB = (sortValueB || "").toString().toLowerCase();
        return sortDirection === "asc" ? stringA.localeCompare(stringB) : stringB.localeCompare(stringA);
      }
    });
  }, [jobs, sortedColumn, sortDirection]);


  const getSortIcon = (column: string) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "5px",
      }}
    >
      {sortedColumn === column && sortDirection === "asc" ? (
        <>
          <img src={Arrowicon} alt="404_image" />
        </>
      ) : (
        <>
          <img src={Arrowicon} alt="404_image" />
        </>
      )}
    </div>
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const theme = createTheme({
    components: {
      // Name of the component ⚛️
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            fontFamily: "Nunito",
            textTransform: "capitalize",
          },
        },
      },
    },
  });

  const getStatusColor = (status: any) => {
    switch (status) {
      case "PENDING":
        return { status: "Pending", color: "orange" };
      case "RUNNING":
        return { status: "Running", color: "orange" };
      case "SUCCEEDED":
        return { status: "Succeeded", color: "green" };
      case "DECLINED":
        return { status: "Declined", color: "red" };
      default:
        return { status: "Unknown", color: "inherit" };
    }
  };

  const getStatusRiskScoreColor = (score: any) => {
    if(score >= 0 && score <= 99) {
      return '#4CAF50'
    } else if(score > 99 && score <= 499) {
      return '#FF9800'
    } else if(score > 500) {
      return '#F44336'
    }
  }

  const fetchData = async () => {
    try {
      const response = await getJobs();
      setJobs(response);
      setLoading(false); 
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
        {loading ? ( 
        <Loader/>
        ) : jobs.length === 0 ? (
        <p style={{
          textAlign: 'center', marginTop: '20px', fontFamily: "Nunito",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px"
        }}>No jobs found. If you want to create a job please go to  <a href="/Form" style={{ color: "#1976d2" }}>New Job </a></p>
      ) : (
        <TableContainer
          style={{
            marginTop: "30px",
            paddingTop: "20px",
            paddingLeft: "30px",
            paddingRight: "30px",
            borderRadius: "5px",
            width: "auto",
            border: "2px solid rgba(236, 236, 237, 1)",
          }}
          component={Paper}
        >
          <ThemeProvider theme={theme}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead style={{ paddingTop: "30px" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    No.
                  </TableCell>
                  <TableCell onClick={() => sortData("id")}>
                    <span
                      style={{
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Job ID
                      {getSortIcon("id")}
                    </span>
                  </TableCell>
                  <TableCell onClick={() => sortData("companyName")}>
                    <span
                      style={{
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Company
                      {getSortIcon("companyName")}
                    </span>
                  </TableCell>
                  <TableCell onClick={() => sortData("duration")}>
                    <span
                      style={{
                        width: "max-content",
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Job Duration
                      {getSortIcon("duration")}
                    </span>
                  </TableCell>
                  <TableCell onClick={() => sortData("createdAt")}>
                    <span
                        style={{
                          width: "max-content",
                          fontWeight: "600",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                    >
                      Date
                      {getSortIcon("createdAt")}
                    </span>
                  </TableCell>
                  <TableCell
                    onClick={() => sortData("createdAt")}
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    <span
                      style={{
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Time
                      {getSortIcon("createdAt")}
                    </span>
                  </TableCell>
                  <TableCell
                    onClick={() => sortData("status")}
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    <span
                      style={{
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Status
                      {getSortIcon("status")}
                    </span>
                  </TableCell>
                  <TableCell
                    onClick={() => sortData("riskScore")}
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    <span
                      style={{
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Score
                      {getSortIcon("riskScore")}
                    </span>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedJobs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((job, index) => {
                    const isLastRowInPagination =
                      index === rowsPerPage - 1 &&
                      page === Math.floor(sortedJobs.length / rowsPerPage);

                    const minutes = Math.floor(job.duration / 60000);
                    const seconds = Math.floor((job.duration % 60000) / 1000);

                    return (
                      <TableRow
                        key={job.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        onClick={() => handleRowClick(job.id)} // Handle row click event
                        className={`${styles.table} ${isLastRowInPagination ? "no-bottom-border" : ""
                          }`}
                      >
                        <TableCell className={styles.number}>
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "rgba(30, 136, 229, 1)",
                          }}
                        >
                          <Link to={`/Job-listings/${job.id}`}>{job.id}</Link>
                        </TableCell>
                        <TableCell>{job.companyName}</TableCell>
                        <TableCell
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                        >
                          {job.duration && minutes + "m:" + seconds + 's'}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                        >
                          {formatDate(job.createdAt)}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                        >
                          {formatTime(job.createdAt)}
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              color: getStatusColor(job.status).color,
                              textTransform: "capitalize",
                            }}
                          >
                            {getStatusColor(job.status).status}
                          </span>
                        </TableCell>
                        <TableCell>
                          {job.status === "SUCCEEDED" && job.riskScore !== "" && job.riskScore && (
                            <span
                              style={{
                                color: "#ffff",
                                fontWeight: "bold",
                                background: getStatusRiskScoreColor(job.riskScore),
                                borderRadius: "90px",
                                padding: "5px",
                              }}
                            >
                              {job.riskScore}
                            </span>)}
                        </TableCell>
                        <TableCell>
                          <KeyboardArrowRightIcon
                            style={{ color: "rgba(238, 238, 238, 1)" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </ThemeProvider>
        </TableContainer>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Stack
          spacing={1}
          mt={2}
          alignItems="center"
          style={{
            background: "#ffff",
            width: "auto",
            borderRadius: "5px",
            padding: "7px",
            border: "1px solid rgba(237, 237, 237, 1)",
          }}
        >
          <Pagination
            count={Math.ceil(jobs.length / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => {
              setPage(newPage - 1);
            }}
            sx={{
              "& .MuiPaginationItem-root": {
                minWidth: "20px",
                height: "20px",
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#1565C0",

                padding: "0px",
                color: "white",
              },
              "& .MuiPaginationItem-root:hover": {
                backgroundColor: "#1565C0",

                padding: "0px",
                color: "white",
              },
            }}
          />
        </Stack>
      </div>
    </>
  );
};

export default JobsTable;

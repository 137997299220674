import { Instance } from "./api-client";

export async function getNationns() {
  try {
    //debugger;
    const response = await Instance.get("/nations");
    return response.data;
  } catch (error) {
    console.error("Error fetching Ateco codes:", error);
    return [];
  }
}

export async function getTags() {
  try {
    //debugger;
    const response = await Instance.get("/tags");
    return response.data;
  } catch (error) {
    console.error("Error fetching Ateco codes:", error);
    return [];
  }
}

export async function getAtecoCodes() {
  try {
    //debugger;
    const response = await Instance.get("/ateco-codes");
    return response.data;
  } catch (error) {
    console.error("Error fetching Ateco codes:", error);
    return [];
  }
}

export async function getJobs() {
  try {
    //debugger;
    const response = await Instance.get("/jobs");
    return response.data;
  } catch (error) {
    console.error("Error fetching Ateco codes:", error);
    return [];
  }
}

export async function getJobDetails(jobId: any) {
  try {
    const response = await Instance.get(`/jobs/${jobId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching job details for job ID ${jobId}:`, error);
    return null;
  }
}

export async function getAtecoDescription(ateCode: any) {
  try {
    const response = await Instance.get(`/ateco-codes?code=${ateCode}`);
    //console.log(response.data);

    return response.data;

  } catch (error) {
    console.error(`Error fetching ateco description for job ID ${ateCode}:`, error);
    return null;
  }
}

export async function postJobsPost(data: any) {
  try {
    //debugger;

    const response = await Instance.post("/start-job", data);
    //console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error making POST request:", error);
    throw error;
  }
}

export async function postJobsStats(data: any) {
  try {
    //debugger;

  const response = await Instance.post('/stats', data)
    //console.log(response.data, 'Stat');
    return response.data;
  } catch (error) {
    console.error("Error making POST request:", error);
    throw error;
  }
}

import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import SnackbarComponent from '../components/snackbar/snackbar';
import JobsForm from '../components/jobs-form/jobs-form';
import Layout from '../components/layout/layout';
import styled from 'styled-components';

const ContainerPageForm = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 53px;
  padding-bottom: 53px;
  gap: 65px;
`;

const FormJob = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  // Check if the user is authenticated when the component mounts
  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: false })
      .then(() => {
        setUserLoggedIn(true);
        const hasShownSnackbar = localStorage.getItem('hasShownSnackbar');
        if (!hasShownSnackbar) {
          setShowSnackbar(true); // Show the Snackbar only once
          localStorage.setItem('hasShownSnackbar', 'true');
        }
      })
      .catch(() => {
        setUserLoggedIn(false);
        // Redirect to the login page if the user is not authenticated
        navigate('/login', { replace: true });
      });
  }, [navigate]);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <>
      {userLoggedIn && (
        <Layout maxWidth="lg">
          <ContainerPageForm>
            <JobsForm />
          </ContainerPageForm>
          <SnackbarComponent
            severity="success"
            isOpen={showSnackbar}
            message="Autenticazione avvenuta con successo"
            handleCloseSnackbar={handleCloseSnackbar}
          />
        </Layout>
      )}
    </>
  );
};

export default FormJob;

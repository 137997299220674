import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from 'react';
import { IconButton } from '@mui/material';
import { SnackbarProps } from '../../types/types';

const SnackbarComponent = ({isOpen, handleCloseSnackbar, message, severity} : SnackbarProps) => {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      
    const action = (
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      );

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
            message={message}
            action={action}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
            <Alert onClose={handleCloseSnackbar} severity={severity}  sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )

}

export default SnackbarComponent;
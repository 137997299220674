import Layout from "../components/layout/layout"
import styled from "styled-components";
import NotFoundImage from '../assets/images/ErrorImage.svg'
import typography from "../theme/typography";

const PageNotFound = () => {
    const ContainerPage = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
    `

    const Title = styled.h1`
        font-family: ${typography.fontFamily}
        font-weight: ${typography.fontWeightBold}
        font-size: ${typography.h1?.fontSize}
        line-height: ${typography.h1?.lineHeight}
    `

    const Paragraph = styled.p`
        font-family: ${typography.fontFamily}
        font-weight: ${typography.fontWeightBold}
        font-size: ${typography.h3?.fontSize}
        line-height: ${typography.h3?.lineHeight}
    `

    return (
        <Layout maxWidth="lg">
            <ContainerPage>
                <img 
                    src={NotFoundImage}  
                    alt="404_image"
                />
                <Title>Oops! Something went wrong</Title>
                <Paragraph>This page doesn’t exit or removed. Back to <a href="/dashboard">homepage</a>.</Paragraph>
            </ContainerPage>
        </Layout>
    )
}

export default PageNotFound
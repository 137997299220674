import styled from "styled-components";
import Typography from "../../theme/typography";
import ThemeColors from "../../theme/colors";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAtecoDescription, getJobDetails } from "../../api-call";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loader from "../loader/loader";
//import Circle from '../../assets/images/circle.svg';

interface JobDetails {
  id: string;
  domains: string[];
  cidr: string[];
  company: string;
  tags: string[];
  atecoCode: string;
  owner: string;
  email: string;
  createdAt: string;
  status: string;
  type: string;
  countries: string[];
  [key: string]: any; // Index signature allowing indexing with a string
}
const LayoutFormJob = styled.div`
  background: white;
  padding: 56px;
  border-radius: 15px;
  border: 2px solid ${ThemeColors.primary.gray};
  font-size: ${Typography.h1?.fontSize};
`;
const LayoutHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 40px;
`;
const FormJobTitle = styled.h1`
  font-size: ${Typography.h1?.fontSize};
  font-weight: ${Typography.fontWeightBold};
  line-height: ${Typography.h1?.lineHeight};
  text-transform: capitalize;
`;
const LayoutBox = styled.div`
  display: flex;
  gap: 20%;
  padding: 20px;
  border-radius: 15px;
  border: 2px solid ${ThemeColors.primary.gray};
  margin-bottom: 40px;
`;
const LayoutminBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const FormId = styled.p`
  font-size: ${Typography.body2?.fontSize};
  font-weight: ${Typography.fontWeightBold};
  line-height: ${Typography.body2?.lineHeight};
  margin: 0;
`;
const FormLabel = styled.h5`
  font-size: 17px;
  font-weight: ${Typography.fontWeightBold};
  line-height: ${Typography.h5?.lineHeight};
  color: ${ThemeColors.secondary.gray};
  margin: 0;
`;
const FormData = styled.h6`
  font-size: 20px;
  font-weight: ${Typography.fontWeightBold};
  line-height: ${Typography.h6?.lineHeight};
  color: black;
  margin: 0;
`;

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

const getStatusRiskScoreColor = (score: any) => {
  if(score >= 0 && score <= 99) {
    return '#4CAF50'
  } else if(score > 99 && score <= 499) {
    return '#FF9800'
  } else if(score > 500) {
    return '#F44336'
  }
}

const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
};

const JobDetailsPage = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [jobDetails, setJobDetails] = useState<JobDetails | null>(null);
  const [atecoDescription, setAtecoDescription] = useState<string>("");

  const getStatusColor = (status: any) => {
    switch (status) {
      case "PENDING":
        return "orange"; // Apply orange color for pending status
      case "RUNNING":
        return "orange"; // Apply orange color for pending status
      case "SUCCEEDED":
        return "green"; // Apply green color for SUCCEEDED status
      case "DECLINED":
        return "red"; // Apply red color for declined status
      default:
        return "inherit";
    }
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const details = await getJobDetails(jobId);
        setJobDetails(details);

        // Fetch Ateco description using the API
        const ateCode = details.atecoCode;
        if (ateCode) {
          try {
            const response = await getAtecoDescription(ateCode)
            //console.log(response, 'Description')
            const descriptions = response.map((ateco: { description: any }) => ateco.description);
            setAtecoDescription(descriptions);
          } catch (error) {
            console.error("Error fetching Ateco description:", error);
          }
        }
      } catch (error) {
        console.error(`Error fetching job details for job ID ${jobId}:`, error);
      }
    };

    fetchJobDetails();
  }, [jobId]);


  if (!jobDetails) {
    return <Loader />;
  }

  return (
    <LayoutFormJob>
      <div style={{ display: "flex", alignItems: "left", justifyContent: 'space-between' }}>
        <FormId
          style={{
            color: "#046ECB",
          }}
        >
          #{jobDetails.id}
        </FormId>
        {jobDetails.status === "SUCCEEDED" && (
        <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', gap: '5px' }}>
          <FormId>
          <Link style={{color: "#046ECB"}} to={`/Job-listings/${jobDetails.id}/${jobDetails.companyId}`}>Go to Analitycs</Link>
          </FormId>
          <ArrowForwardIosIcon style={{color: "#046ECB", fontSize: '14px'}}/>
        </div> )}
      </div>
      <LayoutHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          <FormJobTitle>{jobDetails.companyName}</FormJobTitle>
          <FormId
            style={{
              color: getStatusColor(jobDetails.status),
            }}
          >
            <CircleIcon
              style={{
                fontSize: "10px",
                marginRight: "5px",
              }}
            />
            {jobDetails.status}
          </FormId>
        </div>
        {jobDetails.status === "SUCCEEDED" && (
          <span
            style={{
              color: "#ffff",
              background: getStatusRiskScoreColor(jobDetails.riskScore),
              borderRadius: "90px",
              padding: "15px",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "12px",
              height: "12px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {jobDetails.riskScore} / 1000
          </span>
        )}
      </LayoutHeader>
      <LayoutBox>
        <LayoutminBox>
          <FormLabel>Date created</FormLabel>
          <FormData>{formatDate(jobDetails.createdAt)}</FormData>
        </LayoutminBox>
        <LayoutminBox>
          <FormLabel>Time created</FormLabel>
          <FormData>{formatTime(jobDetails.createdAt)}</FormData>
        </LayoutminBox>
      </LayoutBox>
      {jobDetails.atecoCode && (
      <LayoutBox>
        <LayoutminBox>
          <FormLabel>Ateco code</FormLabel>
          <FormData>{jobDetails.atecoCode}</FormData>
          <LayoutminBox>
          <FormLabel>Description</FormLabel>
          <FormData>{atecoDescription}</FormData>
        </LayoutminBox>
        </LayoutminBox>
      </LayoutBox>
      )}
      {jobDetails.tags && (
      <LayoutBox>
        <LayoutminBox>
        <FormLabel>Tags</FormLabel>
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
        {jobDetails.tags.map((tag, index) => (
            <FormData
              key={index}
              style={{
                borderRadius: "4px",
                border: "1px solid rgba(21, 101, 192, 0.04)",
                background: "rgba(21, 101, 192, 0.04)",
                padding: "6px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {tag}
            </FormData>
        ))}
        </div>
        </LayoutminBox>
      </LayoutBox> )}
      {jobDetails.cidr && (
      <LayoutBox>
      <LayoutminBox>
        <FormLabel>Network</FormLabel>
        <div style={{display: 'grid',gridTemplateColumns: 'repeat(4, 1fr)',gap: '10px',}}>
          {jobDetails.cidr.map((ip, index) => (
            <FormData
              key={index}
              style={{
                borderRadius: "4px",
                border: "1px solid rgba(21, 101, 192, 0.04)",
                background: "rgba(21, 101, 192, 0.04)",
                padding: "6px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ip}
            </FormData>
          ))}
        </div>
      </LayoutminBox>
      </LayoutBox>
      )}
      <LayoutBox>
      <LayoutminBox>
        <FormLabel>Domains</FormLabel>
        <div style={{display: 'grid',gridTemplateColumns: 'repeat(4, 1fr)',gap: '10px',}}>
        {jobDetails.domains.map((domain, index) => (
            <FormData
              key={index}
              style={{
                borderRadius: "4px",
                border: "1px solid rgba(21, 101, 192, 0.04)",
                background: "rgba(21, 101, 192, 0.04)",
                padding: "6px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {domain}
            </FormData>
          ))}
        </div>
        </LayoutminBox>
      </LayoutBox>
      {(jobDetails.countries && jobDetails.countries.length > 0 && jobDetails.countries.every(country => country !== '')) && (
      <LayoutBox>
        <LayoutminBox>
          <FormLabel>Location</FormLabel>
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
        {jobDetails.countries && jobDetails.countries.map((country, index) => (
            <FormData
              key={index}
              style={{
                borderRadius: "4px",
                border: "1px solid rgba(224, 224, 224, 1)",
                padding: "6px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {country}
            </FormData>
        ))}
        </div>
        </LayoutminBox>
      </LayoutBox> )}
    </LayoutFormJob>
  );
};

export default JobDetailsPage;

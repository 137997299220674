import React, {useCallback, useEffect, useState} from "react";
import Layout from "../components/layout/layout";
import styled from "styled-components";
import Breadcrumb from "../components/breadcrumbs/breadcrumb";
import typography from "../theme/typography";
import ThemeColors from "../theme/colors";
import {
  Select,
  FormControlLabel,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import Typography from "../theme/typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chart from "react-apexcharts";
import { getJobDetails, postJobsStats } from "../api-call";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, InputLabel } from "@mui/material";
import { useParams } from "react-router-dom";
import Loader from "../components/loader/loader";

const ContainerHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;
const ContainerPageTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const PageTitle = styled.div`
  font-weight: ${typography.fontWeightBold};
  font-size: ${typography.h3?.fontSize};
  line-height: ${typography.h3?.lineHeight};
  color: ${ThemeColors.primary.dark};
`;
const ContainerBreadcrumb = styled.div`
  margin-bottom: 40px;
`;

const ContainerBox = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  border: 2px solid rgba(236, 236, 237, 1);
`;

const Container = styled.div<{ $fullWidth?: boolean; }>`
  grid-template-columns: ${props => props.$fullWidth ? "1fr" : "1fr 1fr"};
  display: grid;
  grid-direction: row;
  justify-content: space-between;
  gap: 5%;
`;

const Containerfilters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 30px;
  padding-bottom: 30px;
`;

const FormJobTitle = styled.h3`
  font-size: ${Typography.h3?.fontSize};
  font-weight: ${Typography.fontWeightBold};
  line-height: ${Typography.h3?.lineHeight};
  text-transform: capitalize;
`;

const theme = createTheme({
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiMenuItem-root:hover": {
            background: "rgba(21, 101, 192, 0.04)", // Red background for selected MenuItem when hovered
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiMenu-paper": {
            border: "1px solid rgba(113, 180, 239, 1)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(113, 180, 239, 1)",
            padding: "0px 2px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid rgba(236, 236, 237, 1)",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(113, 180, 239, 1)",
            padding: "0px 2px",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingLeft: "8px", // Add padding to the top of the list items
          paddingRight: "8px", // Add padding to the bottom of the list items
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          //fontSize: '30px', // Red background for selected MenuItem when hovered
        },
      },
    },
  },
});

type ChartData = {
  data: {
    x: string;
    y: number;
    goals: {
      strokeColor: string;
      strokeHeight: number;
      name: string;
      value: number;
    }[];
  }[];
  name: string;
};

type StackedChartData = {
  series: ChartData[];
  options: ApexCharts.ApexOptions;
};
interface DataRow {
  measure_name: string;
  measure_value: string;
}
const staticData: DataRow[] = [
  { measure_name: "Total IP", measure_value: "" },
  { measure_name: "Active IP", measure_value: "" },
  { measure_name: "Total Services", measure_value: "" },
  { measure_name: "Credentials leaked in the last year", measure_value: "" },
  { measure_name: "Credentials leaked more than 3 years ago", measure_value: "" },
  { measure_name: "Credentials leaked in the previous 2 years", measure_value: "" },
  { measure_name: "Domains without DMARC", measure_value: "" },
  { measure_name: "Expired Certificates", measure_value: "" },
  { measure_name: "Malicious IP", measure_value: "" },
  { measure_name: "Suspicious IP", measure_value: "" },
  { measure_name: "Total Domains", measure_value: "" },
  { measure_name: "Critical Severity Vulnerabilities", measure_value: "" },
  { measure_name: "High Severity Vulnerabilities", measure_value: "" },
  { measure_name: "Low Severity Vulnerabilities", measure_value: "" },
  { measure_name: "Medium Severity Vulnerabilities", measure_value: "" }
];
interface JobDetails {
  tags: string[];
  atecoCode: string;
  countries: string[];
}

const ChartPage: React.FC = () => {
  //const [data, setData] = useState([]);
  const [atecoSwitchValue, setAtecoSwitchValue] = React.useState(false);
  const { jobId, companyId } = useParams();
  const [lineChartData, setLineChartData] = React.useState<any>({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false, // Set to false to hide the toolbar
        },
      },
      xaxis: {
        categories: [],
      },
    },
  });
  const [radarChartData, setRadarChartData] = React.useState({
    series: [
      {
        name: "Series 1",
        data: [0, 0, 0, 0], // Initialize with zeros for each category
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "radar",
        toolbar: {
          show: false, // Set to false to hide the toolbar
        },
      },
      xaxis: {
        categories: ["Network", "Geopolitical", "User", "Domains"],
      },
    },
  });
  const [stackedChartData, setStackedChartData] =
    React.useState<StackedChartData>({
      series: [
        {
          name: "0-99",
          data: [],
        },
        {
          name: "100-499",
          data: [],
        },
        {
          name: "500-1000",
          data: [],
        },
        {
          name: "Your score",
          data: [],
        },
      ],
      options: {
        chart: {
          height: 380,
          type: "bar",
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false, // Set to false to hide the toolbar
          },
        },
        xaxis: {
          labels: {
            formatter: (value: any) => String(Number(value * 10))
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return String(Number(val * 10));
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: [
            '#4CAF50', '#FF9800', '#F44336', '#1565C0'
        ],
        fill: {
          opacity: 1
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ["0-99", "100-499", "500-1000", "Your score"],
        },
      },
    });
  const [rows, setRows] = React.useState<DataRow[]>([]);
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const [labelHidden, setLabelHidden] = React.useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [companyName, setCompanyName] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [labelHiddenCountry, setLabelHiddenCountry] = React.useState(false);
  const [atecoCode, setAtecoCode] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if(!selectedTag.length && !selectedCountry.length && !atecoCode) {
      populateSelectOnStart();
    }
    fetchData();
  }, [selectedTag, selectedCountry, atecoSwitchValue]); //fetch data when all filters changes

  const populateSelectOnStart = async () => {
    try {
      const getJobsDetailsData = await getJobDetails(jobId);

      setTags(getJobsDetailsData.tags);
      setCountries(getJobsDetailsData.countries);
      setAtecoCode(getJobsDetailsData.atecoCode);

      if (getJobsDetailsData.tags.length > 0) {
        setLabelHidden(true);
        setSelectedTag(getJobsDetailsData.tags);
      }

      if(getJobsDetailsData.countries.length > 0) {
        setLabelHiddenCountry(true)
        setSelectedCountry(getJobsDetailsData.countries);
      }
    } catch (e) {
      console.error('Error on populate select', e);
    }
  }

  const fetchData = async ()=> {
    try {
      const response = await postJobsStats(dataPostJob);
      const history = response.history;
      const graphStats = response.graph_stats;
      const historyCurrentJobId = history.filter((ele: any) => jobId === ele.data.job_id);

      setCompanyName(historyCurrentJobId[0].data.company_name);

      //const totalRisk = parseFloat(response.history[0].data.total_risk);
      const currentJobRisk = historyCurrentJobId[0].data.total_risk;
      //bar charts
      setStackedChartData((prevData) => ({
        ...prevData,
        series: prevData.series.map((serie) => ({
          ...serie,
          data: [
            {
              x: '2023-1-09',
              y: graphStats[serie.name],
              goals: [
                {
                  strokeColor: "#1565C0",
                  strokeHeight: 100,
                  name: "Your score",
                  strokeWidth: 2,
                  value: parseInt(currentJobRisk) / 10,
                },
              ],
            },
          ],
        })),
      }));

      //line chart
      if (history.length > 0) {
        //show line chart only if there are two jobs
        setLineChartData({
          series: [{
            name: "Score",
            data: history.map((lineChartElement: any) => lineChartElement.data?.total_risk)
          }],
          options: {
            chart: {
              height: 350,
              type: "radar",
              toolbar: {
                show: false, // Set to false to hide the toolbar
              },
            },
            stroke: {
              curve: 'straight'
            },
            dataLabels: {
              enabled: true,
            },
            xaxis: {
              title: {
                text: 'Date'
              },
              categories: history.map((lineChartElement: any) => {
                return lineChartElement.data.time.split(' ')[0];
              }),
              labels: {
                rotate: 0,
              },
            },
            yaxis: {
              title: {
                text: 'Total Risk'
              },
            },
            tooltip: {
              enabled: true, // Abilita le tooltip
              x: {
                formatter: function (value: any ) {
                  // Recupera la data e l'ora dal tuo array di date
                  const dateTime = history[value - 1]?.data?.time.split('.');
                  // Puoi personalizzare il testo della tooltip qui in base alle tue esigenze
                  return 'Data e Ora: ' + dateTime[0];
                }
              },
            },
          },
        });

        const networkRisk = parseFloat(historyCurrentJobId[0].data.network_risk);
        const geopoliticalRisk = parseFloat(historyCurrentJobId[0].data.geopolitical_risk);
        const userRisk = parseFloat(historyCurrentJobId[0].data.users_risk);
        const domainsRisk = parseFloat(historyCurrentJobId[0].data.domains_risk);
        setRadarChartData({
          series: [
            {
              name: "Series 1",
              data: [networkRisk, geopoliticalRisk, userRisk, domainsRisk],
            },
          ],
          options: {
            chart: {
              height: 350,
              type: "radar",
              toolbar: {
                show: false, // Set to false to hide the toolbar
              },
            },
            xaxis: {
              categories: ["Network", "Geopolitical", "User", "Domains"],
            },
          },
        });

        const {
          total_ip,
          active_ip,
          total_services,
          creds_leaked_last_year,
          creds_leaked_more_than_three_years_ago,
          creds_leaked_previous_two_years,
          domains_without_dmarc,
          expired_certificates,
          ip_malicious,
          ip_suspicious,
          total_domains,
          vulns_critical,
          vulns_high,
          vulns_low,
          vulns_medium,
        } = historyCurrentJobId[0].data;

        // Update the measure_value in the staticData array with the fetched values
        const updatedData = staticData.map((item) =>
            item.measure_name === "Total IP"
                ? { ...item, measure_value: total_ip }
                : item.measure_name === "Active IP"
                    ? { ...item, measure_value: active_ip }
                    : item.measure_name === "Total Services"
                        ? { ...item, measure_value: total_services }
                        : item.measure_name === "Credentials leaked in the last year"
                            ? { ...item, measure_value: creds_leaked_last_year }
                            : item.measure_name === "Credentials leaked more than 3 years ago"
                                ? { ...item, measure_value: creds_leaked_more_than_three_years_ago }
                                : item.measure_name === "Credentials leaked in the previous 2 years"
                                    ? { ...item, measure_value: creds_leaked_previous_two_years }
                                    : item.measure_name === "Domains without DMARC"
                                        ? { ...item, measure_value: domains_without_dmarc }
                                        : item.measure_name === "Expired Certificates"
                                            ? { ...item, measure_value: expired_certificates }
                                            : item.measure_name === "Malicious IP"
                                                ? { ...item, measure_value: ip_malicious }
                                                : item.measure_name === "Suspicious IP"
                                                    ? { ...item, measure_value: ip_suspicious }
                                                    : item.measure_name === "Total Domains"
                                                        ? { ...item, measure_value: total_domains }
                                                        : item.measure_name === "Critical Severity Vulnerabilities"
                                                            ? { ...item, measure_value: vulns_critical }
                                                            : item.measure_name === "High Severity Vulnerabilities"
                                                                ? { ...item, measure_value: vulns_high }
                                                                : item.measure_name === "Low Severity Vulnerabilities"
                                                                    ? { ...item, measure_value: vulns_low }
                                                                    : item.measure_name === "Medium Severity Vulnerabilities"
                                                                        ? { ...item, measure_value: vulns_medium }
                                : item
        );
        setRows(updatedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedTag>) => {
    setLabelHidden(true);
    const {
      target: { value },
    } = event;

    setSelectedTag(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeCountry = (event: SelectChangeEvent<typeof selectedCountry>) => {
    setLabelHiddenCountry(true);
    const {
      target: { value },
    } = event;
  
    const countryValue = typeof value === 'string' ? value.split(',') : value;
    //reset switch if italy is not includes into array of country
    if(!countryValue.includes('Italy')) {
      setAtecoSwitchValue(false);
    }
    setSelectedCountry(countryValue);
  };

  const handleFocus = () => {
    if (!selectedTag.length) {
      setLabelHidden(true);
    }
  };

  const handleBlur = () => {
    if (!selectedTag.length) {
      setLabelHidden(false);
    }
  };

  const handleFocusCountry = () => {
    if (!selectedCountry.length) {
      setLabelHiddenCountry(true);
    }
  };
  
  const handleBlurCountry = () => {
    if (!selectedCountry.length) {
      setLabelHiddenCountry(false);
    }
  };

  const dataPostJob = {
    httpMethod: "POST",
    queryStringParameters: {
      countries: selectedCountry,
      ateco: atecoSwitchValue && atecoCode || '',
      tags: selectedTag,
      company_id: companyId,
      job_id: jobId,
    },
  };

  const handleAtecoSwitch = useCallback(() => {
    setAtecoSwitchValue(!atecoSwitchValue);
  }, [atecoSwitchValue])

  return (
    <Layout maxWidth="lg">
      <ContainerHeader>
        <ContainerPageTitle>
          <PageTitle>Analytics</PageTitle>
        </ContainerPageTitle>
        <ContainerBreadcrumb>
          <Breadcrumb pageName={"Analytics"} />
        </ContainerBreadcrumb>
      </ContainerHeader>
      <FormJobTitle>{companyName}</FormJobTitle>
      <Containerfilters>
        {(loading || !tags.length || !countries.length || !lineChartData.series.length) && (
            <Loader />
        ) }
        <ThemeProvider theme={theme}>
          <Box sx={{ width: "340px", height: "40px", borderRadius: "10px" }}>
            <FormControl
              fullWidth
              sx={{
                "& .MuiFormControl-root, & .MuiFormLabel-root": {
                  color: "rgba(0, 0, 0, 0.87)",
                },
              }}
            >
              {!labelHidden && (
                <InputLabel
                  sx={{
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "21px",
                    animation: "none",
                    transition: "none",
                  }}
                  id="demo-simple-select-label"
                >
                  Tags
                </InputLabel>
              )}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                value={selectedTag}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                displayEmpty
                inputProps={{
                  style: {
                    padding: 0, // Remove padding from the input
                  },
                }}
                fullWidth
                sx={{
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  background: "#ffffff",
                  "& .MuiSelect-icon": {
                    color: "#000", // Change the color of the arrow icon (black)
                  },
                  "& .MuiMenuItem-root:hover": {
                    background: "#2196f3", // Blue background on hover
                    color: "#fff", // White text on hover
                  },
                  "& .MuiSelect-select:active, & .MuiSelect-select:focus, & .MuiSelect-select:hover":
                    {
                      background: "ffff",
                    },
                }}
              >
                {tags.map((tag, index) => (
                  <MenuItem
                    key={index}
                    value={tag}
                    sx={{
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                    }}
                  >
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: "340px", height: "40px", borderRadius: "10px" }}>
        <FormControl

          fullWidth
          sx={{
            "& .MuiFormControl-root, & .MuiFormLabel-root": {
              color: "rgba(0, 0, 0, 0.87)",
            },
          }}
        >
          {!labelHiddenCountry && (
            <InputLabel
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "21px",
                animation: "none",
                transition: "none",
              }}
              id="demo-simple-select-label"
            >
              Locations
            </InputLabel>
          )}
          <Select
            multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCountry}
            onChange={handleChangeCountry}
            onFocus={handleFocusCountry}
            onBlur={handleBlurCountry}
            displayEmpty
            inputProps={{
              style: {
                padding: 0, // Remove padding from the input
              },
            }}
            fullWidth
            sx={{
              fontFamily: "Nunito",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "21px",
              background: "#ffffff",
              "& .MuiSelect-icon": {
                color: "#000", // Change the color of the arrow icon (black)
              },
              "& .MuiMenuItem-root:hover": {
                background: "#2196f3", // Blue background on hover
                color: "#fff", // White text on hover
              },
              "& .MuiSelect-select:active, & .MuiSelect-select:focus, & .MuiSelect-select:hover":
                {
                  background: "ffff",
                },
            }}
          >
            {countries.map((country, index) => (
              <MenuItem
                key={index}
                value={country}
                sx={{
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                }}
              >
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
          </Box>
        </ThemeProvider>
        <FormControlLabel
          sx={{
            display: "flex",
            textTransform: "capitalize",
            fontFamily: "Nunito",
            width: "340px",
            height: "20px",
            border: "2px solid rgba(236, 236, 237, 1)",
            borderRadius: "4px",
            backgroundColor: "#ffff",
            fontSize: "14px",
            fontWeight: "500",
            margin: "0px",
            //lineHeight: "21px",
            padding: "15px 14px",
            color: "rgba(0, 0, 0, 0.87)",
            //justifyContent: "start",
          }}
          disabled={!selectedCountry.includes('Italy')}
          control={
            <Switch checked={atecoSwitchValue} onChange={handleAtecoSwitch} />
          }
          label="Ateco"
        />
      </Containerfilters>
      {lineChartData.series[0]?.data.length > 1 && (
      <Container $fullWidth>
        <ContainerBox>
          <h2
            style={{
              fontFamily: "Nunito",
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "33px",
              textAlign: 'center'
            }}
          >
            Risk Score Trend
          </h2>
          <Chart
            options={lineChartData.options as ApexCharts.ApexOptions}
            series={lineChartData.series}
            type="line"
            height={350}
          />
        </ContainerBox>
      </Container>
      )}
      <Container style={{ gridTemplateColumns: "1fr 1fr" }}>
        <ContainerBox>
          <h2
            style={{
              fontFamily: "Nunito",
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "33px",
              textAlign: 'center'
            }}
          >
            Risk Score Drill down
          </h2>
          <Chart
            options={radarChartData.options as ApexCharts.ApexOptions}
            series={radarChartData.series}
            type="radar"
            height={350}
          />
        </ContainerBox>

        <ContainerBox>
          {" "}
          <h2
              style={{
                fontFamily: "Nunito",
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "33px",
                textAlign: 'center'
              }}
          >
            Risk Score Comparison
          </h2>
          <Chart
              options={stackedChartData.options}
              series={stackedChartData.series}
              type="bar"
          />
          <br />
          <p
              style={{
                fontFamily: "Nunito",
                fontSize: "12px",
                fontStyle: "italic",
                display: "flex",
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: "center",
              }}
          >
            Your score is compared to other companies{" "}
            {selectedTag && ` with selected tag(s): ${selectedTag} `}
            {selectedCountry.length > 0 && ` and with selected nation(s): ${selectedCountry}`}
            {selectedCountry && selectedCountry.includes('Italy') && atecoSwitchValue && ` and with your Ateco code ${atecoCode}`}
          </p>
        </ContainerBox>
      </Container>{" "}
      <Container $fullWidth>
        <ContainerBox>
          <div>
            <h2
                style={{
                  fontFamily: "Nunito",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "33px",
                  textAlign: 'center'
                }}
            >
              Evidence Summary
            </h2>

            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                        sx={{
                          fontWeight: "700",
                          fontSize: "18px",
                          fontFamily: "Nunito",
                        }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                        sx={{
                          fontWeight: "700",
                          fontSize: "18px",
                          fontFamily: "Nunito",
                        }}
                    >
                      Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                      <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontFamily: "Nunito",
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                          {row.measure_name}
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                              fontFamily: "Nunito",
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                          {row.measure_value}
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </ContainerBox>
      </Container>
    </Layout>
  );
};
export default ChartPage;
